<template>
  <div class="service-evaluate">
    <template v-if="orderConfig.detail">
      <template v-if="orderConfig.detail.status === 6">
        <div>
          <el-form :model="orderConfig.formData" :rules="orderConfig.formRules"
                   ref="serviceOrderEvaluateForm" label-width="170px">
            <el-form-item class="rate-form-item" :inline-message="true" label="服务态度" prop="serviceAttitudeScore">
              <el-rate
                :colors="colors"
                v-model="orderConfig.formData.serviceAttitudeScore"
                @change="$refs['serviceOrderEvaluateForm'].validateField('serviceAttitudeScore')"
              >
              </el-rate>
            </el-form-item>
            <el-form-item class="rate-form-item" :inline-message="true" label="完成程度" prop="completeStatusScore">
              <el-rate
                :colors="colors"
                v-model="orderConfig.formData.completeStatusScore"
                @change="$refs['serviceOrderEvaluateForm'].validateField('completeStatusScore')"
              >
              </el-rate>
            </el-form-item>
            <el-form-item class="rate-form-item" :inline-message="true" label="交付质量" prop="deliveryQualityScore">
              <el-rate
                :colors="colors"
                v-model="orderConfig.formData.deliveryQualityScore"
                @change="$refs['serviceOrderEvaluateForm'].validateField('deliveryQualityScore')"
              >
              </el-rate>
            </el-form-item>
            <el-form-item class="rate-form-item" :inline-message="true" label="技术能力" prop="technicalAbilityScore">
              <el-rate
                :colors="colors"
                v-model="orderConfig.formData.technicalAbilityScore"
                @change="$refs['serviceOrderEvaluateForm'].validateField('technicalAbilityScore')"
              >
              </el-rate>
            </el-form-item>
            <el-form-item class="rate-form-item" :inline-message="true" label="沟通能力" prop="communicatioScore">
              <el-rate
                :colors="colors"
                v-model="orderConfig.formData.communicatioScore"
                @change="$refs['serviceOrderEvaluateForm'].validateField('communicatioScore')"
              >
              </el-rate>
            </el-form-item>
            <el-form-item class="mg-t10" label="评价内容" prop="content">
              <el-input :rows="8" v-model="orderConfig.formData.content"
                        placeholder="简单发表一下看法..."
                        type="textarea"></el-input>
            </el-form-item>
            <el-form-item>
              <el-upload
                :http-request="fileUploadFunction"
                action=""
                :multiple="true"
                :limit="5"
                :class="{hiddenUpload: orderConfig.formData.picture && orderConfig.formData.picture.length >= 5}"
                :data="{...uploadConfig.uploadEnum.SERVICE}"
                list-type="picture-card"
                :on-success="uploadConfig.uploadSuccess.bind(null, {key: 'picture'})"
                :before-upload="uploadConfig.beforeUpload.bind(null, {key: 'picture'})"
                :on-remove="uploadConfig.onRemove.bind(null,  {key: 'picture'})"
              >
                <i class="el-icon-plus"></i>
                <div class="el-upload__tip" slot="tip">
                  <div>只能上传jpg/png文件，最多上传五张，最大不能超过1M</div>
                </div>
              </el-upload>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="orderConfig.submitClick">发表评价
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </template>
      <template v-else-if="orderConfig.detail.status > 6">
        <div class="sctp-tc" style="padding-top: 60px;">
          <i class="el-icon-success"></i>
          <div class="sctp-color-main">
            <div class="sctp-mar-b15">
              <span class="sctp-mar-r5">评价完成</span>
            </div>
            <div>
              <el-button size="small" type="primary" @click="goPage('/shop/service-order')">返回</el-button>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="sctp-tc">
          当前订单不可评价！
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import {service} from "@/apis";
import uploadEnum from "@/resources/js/uploadenum";

export default {
  name: "evaluate",
  data() {
    let checkRate = function (rule, value, callback) {
      if (!value || value === 0) {
        callback(new Error(rule.message))
      } else {
        callback()
      }
    }
    return {
      orderId: null,
      colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
      orderConfig: {
        detail: null,
        loadDetail: () => {
          service.orderDetail({
            orderId: this.orderId
          }).then(res => {
            let {data} = res;
            this.orderConfig.detail = data;
            if (data.status > 6) {
              this.$emit('finish');
            }
          });
        },
        submitClick: () => {
          this.$refs['serviceOrderEvaluateForm'].validate()
            .then(() => {
              let {
                formData: {
                  score, content, picture, serviceAttitudeScore, completeStatusScore, deliveryQualityScore, technicalAbilityScore, communicatioScore,
                }
              } = this.orderConfig;
              service.submitEvaluate({
                orderId: this.orderId,
                content: content,
                score: score,
                userId: this.user.userId,
                serviceAttitudeScore: serviceAttitudeScore,
                completeStatusScore: completeStatusScore,
                deliveryQualityScore: deliveryQualityScore,
                technicalAbilityScore: technicalAbilityScore,
                communicatioScore: communicatioScore,
                pictures: (picture || []).map(item => item.response.src).join(','),
              }).then(res => {
                this.$message.success('发表评价成功');
                this.orderConfig.loadDetail();
              });
            })
        },
        formData: {
          score: null,
          content: null,
          picture: null,
          serviceAttitudeScore: null,
          completeStatusScore: null,
          deliveryQualityScore: null,
          technicalAbilityScore: null,
          communicatioScore: null,
        },
        formRules: {
          score: [
            {required: true, message: '请选择评分', trigger: ['blur', 'change']},
            {validator: checkRate, message: '请选择评分', trigger: ['blur', 'change']},
          ],
          content: [
            {required: true, message: '简单发表一下看法...', trigger: ['blur', 'change']},
          ],
          serviceAttitudeScore: [
            {required: true, message: '请选择 服务态度 评分', trigger: ['blur', 'change']},
            {validator: checkRate, message: '请选择 服务态度 评分', trigger: ['blur', 'change']},
          ],
          completeStatusScore: [
            {required: true, message: '请选择 沟通能力 评分', trigger: ['blur', 'change']},
            {validator: checkRate, message: '请选择 沟通能力 评分', trigger: ['blur', 'change']},
          ],
          deliveryQualityScore: [
            {required: true, message: '请选择 沟通能力 评分', trigger: ['blur', 'change']},
            {validator: checkRate, message: '请选择 沟通能力 评分', trigger: ['blur', 'change']},
          ],
          technicalAbilityScore: [
            {required: true, message: '请选择 沟通能力 评分', trigger: ['blur', 'change']},
            {validator: checkRate, message: '请选择 沟通能力 评分', trigger: ['blur', 'change']},
          ],
          communicatioScore: [
            {required: true, message: '请选择 沟通能力 评分', trigger: ['blur', 'change']},
            {validator: checkRate, message: '请选择 沟通能力 评分', trigger: ['blur', 'change']},
          ],
        },
      },
      uploadConfig: {
        uploadEnum,
        uploadSuccess: ({key}, response, file, fileList) => {
          if (key === 'picture') {
            this.orderConfig.formData.picture = fileList;
          }
          this.$message.success('文件上传成功！');
        },
        onRemove: ({key}, file, fileList) => {
          if (key === 'picture') {
            this.orderConfig.formData.picture = fileList;
          }
        },
        beforeUpload: ({key}, file) => {
          let result = false;
          if (key === 'picture') {
            const allowType = [
              'image/png',
              'image/jpeg'
            ];
            const isImage =
              allowType.some(type => file.type.startsWith(type))
            ;
            const isLtSize = file.size / 1024 / 1024 < 1
            if (!isImage) {
              this.$message.error('只能上传 图片 格式!')
            }
            if (!isLtSize) {
              this.$message.error('文件大小不能超过1M!')
            }
            result = isImage && isLtSize;
          }
          return result && this.$message('文件正在上传') && true;
        },
        fileExceed() {
          this.$message.warning(`文件数量已达上限！`);
        },
        uploadFileError() {
          this.$message.error('文件上传失败')
        },
      },
    }
  },
  beforeMount() {
    let {query: {orderId}} = this.$route;
    if (orderId) {
      this.orderId = orderId;
      this.orderConfig.loadDetail();
    } else {
      this.goPage('/');
    }
  }
}
</script>

<style scoped lang="scss">
.service-evaluate {
  padding-top: 50px;
  min-height: 50vh;

  .el-icon-success {
    font-size: 100px;
    color: #F90;
    margin-bottom: 15px;
  }

  .rate-form-item {
    margin-bottom: 0;

    /deep/ {
      .el-form-item__label, .el-form-item__content {
        line-height: 1.5;
      }

      .el-rate {
        display: inline-block;
      }
    }
  }
}
</style>
